import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePropelAuthContext } from 'src/auth/useAuthContext';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { redirectToKDS } from './utils';

type Props = {
  children: ReactNode;
};

export default function GuestGuard({ children }: Props) {
  const { pathname, push } = useRouter();

  const { isAuthenticated, permissions } = usePropelAuthContext();

  useEffect(() => {
    if (isAuthenticated) {
      const kdsUrl = redirectToKDS(pathname, permissions);
      const redirectUrl = kdsUrl || PATH_AFTER_LOGIN;
      push(redirectUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return <>{children}</>;
}
